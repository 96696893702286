<template>
<div id="EventDetails">
<v-container>

<!-- if upcoming event  -->
<div v-if="EventData.status == 'Upcoming'" class="d-flex text-center align-center justify-center my-5" 
     style="height: 300px; flex-direction: column;">

    <p class="display-1">{{EventData.title}}</p>
    <p class="headline">Date : {{EventData.DOE}}</p>
    <p class="subtitle-1 animated flash slower infinite">Upcoming, Stay Tuned!</p>

</div>

<!-- if past event  -->
<div v-if="EventData.status == 'Past'">
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="6" class="my-5">
            <v-img :src="EventData.imageUrl" height="400px" alt="Event Image" class="elevation-10 white animated fadeIn slow">
                <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                    </v-row>
                </template>
            </v-img>
        </v-col>
    </v-row>

    <div class="mt-5">
        <p class="subtitle-1">Date : {{EventData.DOE}}</p>
        <p class="headline py-5 my-5">{{EventData.title}}</p>
    </div>

    <p class="my-5 subtitle-1" v-html="EventData.description"></p>
</div>
























    
     <v-btn class="secondary" @click="$router.go(-1)">go back</v-btn>
    <v-btn color="primary " @click="$router.push('/editevent/' + EventSlug)">Edit</v-btn>
    <v-btn color="red darken-1 " @click="deleteDialog = true">Delete</v-btn>

    <!-- delete modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="290" transition="slide-y-transition">
    <v-card>
    <v-card-title class="headline">Confirm Delete?</v-card-title>
    <v-card-text>
        This action can't be undone!
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="primary" text @click="DeleteEvent()">Delete</v-btn>
    </v-card-actions>
    </v-card>
    </v-dialog>
    <!-- delete dialog ends -->
</v-container>
</div>
</template>

<script>
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";

export default {

    name: 'EventDetails',

    metaInfo: {
        title: 'Events'
    },
    
    computed: {

        EventSlug(){ 
          return this.$route.params.eventslug != null ? this.$route.params.eventslug : null;
        },

        EventData(){
            if(this.EventSlug != null) {
                let data =  this.$store.getters.GetEventWithSlug(this.EventSlug);
                return data.length != 0 ? data[0] : this.$router.push("/events");
            }else{ return this.$router.push("/events"); }           
        }
        
    },

    data(){return{

        deleteDialog: false,
    }},

    methods: {

        async DeleteEvent(){
            this.deleteDialog = false;
            // get image url
            let url
            if(this.EventData.imageUrl){
                url = this.EventData.imageUrl.toString();
            }
            // get document where slug equals selected one
            await firebase.firestore().collection("events")
            .where("eventslug", "==", this.EventSlug).get().then(
            (doc) => { if(doc.docs[0].id){
                // update all datas(sorry for cramping it up)
                firebase.firestore().collection("events").doc(doc.docs[0].id).delete()
                .then(
                (result) => { 
                    if(url){
                        firebase.storage().refFromURL(url).delete().then((succ) => {this.$router.push("/events");});
                    }else{this.$router.push("/events");}
                }
                );}       
            }, (err) => { console.log(err);  }); 
        },
    }


}
</script>

<style scoped>

</style>